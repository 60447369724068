import SiteHeader from '@/components/SiteHeader.vue';
export default defineComponent({
  name: 'OverlayBase',
  components: {
    SiteHeader: SiteHeader
  },
  props: {
    show: Boolean,
    transition: {
      type: [String, Boolean],
      default: 'fade-transition'
    }
  },
  data: function data() {
    return {
      dialog: false
    };
  },
  watch: {
    show: function show(value) {
      this.dialog = value;
    }
  },
  mounted: function mounted() {
    this.dialog = this.show;
  }
});