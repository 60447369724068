<template>
  <overlay-base :show="show">
    <v-container
      class="pro-success"
      :fill-height="$vuetify.breakpoint.smAndUp"
      restrict-width-wide
      grid-list-md
      justify-center
    >
      <v-layout align-center justify-center :wrap="$vuetify.breakpoint.xsOnly" skr-side-by-side layout-3>
        <v-flex xs12 md5>
          <v-layout column :align-center="$vuetify.breakpoint.xsOnly">
            <heading :center="$vuetify.breakpoint.xsOnly" :title="$t('billing.settings.overlay.success.title')">
              <template #subtitle_slot>
                {{
                  $t('billing.settings.overlay.success.text', {
                    price: $formatCurrency(planPrices.fairFlat.signatures.QES, usedCurrency),
                  })
                }}
              </template>
            </heading>
            <responsive-image v-if="$vuetify.breakpoint.xsOnly" source="fair-flat-ready" :width="400" :height="672" />
            <v-btn :class="{ 'mt-5': $vuetify.breakpoint.xsOnly }" block color="info" large @click="$emit('close')">
              {{ $t('billing.settings.overlay.success.cta') }}
            </v-btn>
          </v-layout>
        </v-flex>

        <v-flex v-if="$vuetify.breakpoint.smAndUp" xs12 md6 offset-md1>
          <v-layout column align-center>
            <responsive-image source="fair-flat-ready" :width="400" :height="672" />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </overlay-base>
</template>

<script scoped lang="ts">
import Vue from 'vue'

import Heading from '~/components/Heading.vue'
import ResponsiveImage from '~/components/ResponsiveImage.vue'

import OverlayBase from './OverlayBase.vue'

export default Vue.extend({
  name: 'OverlayProUpgradeSuccess',
  components: {
    Heading,
    ResponsiveImage,
    OverlayBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    planPrices(): PricingStructure[keyof PricingStructure] {
      return this.$store.getters['billing/planPrices']
    },
    usedCurrency(): Currency {
      return this.$store.getters['billing/usedCurrency']
    },
  },
})
</script>

<style scoped lang="sass">
.pro-success
  +miw(sm)
    padding-top: 100px
    padding-bottom: 100px
</style>
